import { render, staticRenderFns } from "./employee.vue?vue&type=template&id=dff77c48&scoped=true&"
import script from "./employee.vue?vue&type=script&lang=js&"
export * from "./employee.vue?vue&type=script&lang=js&"
import style0 from "./employee.vue?vue&type=style&index=0&id=dff77c48&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dff77c48",
  null
  
)

export default component.exports